import React from "react";
import LogoEs from "../assets/images/logo_es.png";

export interface CountryConfig {
    apiUrl: string,
    code: string,
    logo: string,
}

export const countries: CountryConfig[] = [
    {
        apiUrl: 'https://pl-crm-api.gowork.com',
        code: 'pl',
        logo: LogoEs,
    },
    {
        apiUrl: 'https://es-crm-api.gowork.com',
        code: 'es',
        logo: LogoEs,
    },
    {
        apiUrl: 'https://fr-crm-api.gowork.com',
        code: 'fr',
        logo: LogoEs,
    },
    {
        apiUrl: 'https://de-crm-api.gowork.com',
        code: 'de',
        logo: LogoEs,
    },
    {
        apiUrl: 'http://127.0.0.1:1533',
        code: 'dv',
        logo: LogoEs,
    },
]

export const isAvailable = (code: string) => {
    return !!countries.find(country => country.code === code);
}

export const loadConfig = (code: string) => {
    return countries.find(country => country.code === code);
}
