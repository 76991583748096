import {Elements} from "@stripe/react-stripe-js";
import {stripePromise} from "../../index";
import React from "react";
import {useCountryStore} from "../../store/CountryStore";

interface Props {
    clientSecret: string;
    children: React.ReactNode
}

export default function Stripe(props: Props) {
    const {country} = useCountryStore();

    return (
        <Elements stripe={stripePromise(country.code)} options={{clientSecret: props.clientSecret}}>
            {props.children}
        </Elements>
    )
}
