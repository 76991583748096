import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {useSetupIntentStyles} from "./SetupIntent.styles";
import {Button, Card, Grid} from "@mantine/core";
import FullscreenLoader from "../FullscreenLoader/FullscreenLoader";
import {useSubscriptionStore} from "../../store/SubscriptionStore";
import {hideNotification, showNotification} from "@mantine/notifications";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useSubscriptionsApi} from "../../api/Susbscription/useSubscriptionsApi";

interface Props {
    subscriptionId: string;
    clientSecret: string;
}

export default function SetupIntent(props: Props) {
    const {classes} = useSetupIntentStyles();
    const stripe = useStripe();
    const elements = useElements();
    const { changeSetupIntent, changeSubscriptions, containsWithoutPaymentMethod } = useSubscriptionStore();
    const { loadSubscriptions } = useSubscriptionsApi();
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        if (elements && stripe) {
            setLoading(true);

            const result = await stripe.confirmSetup({elements, redirect: 'if_required'});

            if (!result.error) {
                changeSetupIntent(null);
                showNotification({
                    color: 'teal',
                    title: 'The card has been added',
                    message: 'We\'ve updated your payment settings',
                    autoClose: false,
                })

                showNotification({
                    color: 'blue',
                    title: 'Payment verification in progress',
                    message: 'It usually does not take more than a few seconds',
                    loading: true,
                    id: 'payment_verification',
                    disallowClose: true,
                    autoClose: false,
                })

                let interval: null|NodeJS.Timer = null;
                let canMakeRequest: boolean = true;

                interval = setInterval(async () => {
                    if (canMakeRequest) {
                        canMakeRequest = false;

                        changeSubscriptions((await loadSubscriptions()).items);

                        if (!containsWithoutPaymentMethod() && interval !== null) {
                            clearInterval(interval);
                            hideNotification('payment_verification');
                        } else {
                            canMakeRequest = true;
                        }
                    }
                }, 2000)
            } else if (result.error.type !== 'validation_error') {
                showNotification({
                    color: 'red',
                    title: t('fillCard.errorOccurred'),
                    message: result.error.type === 'card_error' ? result.error.message : t('fillCard.unexpectedError'),
                    autoClose: false,
                });
            }

            setLoading(false);
        }
    }

    return (
        <>
            {
                (stripe && elements) &&
                <Grid mt={30} mb={30}>
                    <Grid.Col span={12} md={5}>
                        <PaymentElement></PaymentElement>
                        <Button onClick={handleSubmit} fullWidth={true} mt={20} loading={loading}>Save</Button>
                    </Grid.Col>
                </Grid>
            }

            {
                !stripe &&
                    <FullscreenLoader></FullscreenLoader>
            }
        </>
    )
}
