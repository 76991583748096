import {Badge, Table, Text} from "@mantine/core";
import {dinero} from "../../lib/dinero";
import {useTranslation} from "react-i18next";
import Section from "../Section/Section";
import {useSubscriptionStore} from "../../store/SubscriptionStore";

interface Props {
}
export default function Subscriptions(props: Props) {
    const {t} = useTranslation();
    const {subscriptions} = useSubscriptionStore();

    if (subscriptions.length === 0) {
        return <></>
    }

    return (
        <Section title={t('subscriptions.subscriptions')}>
            <Table>
                <tbody>
                { subscriptions.map((subscription) =>
                    <>
                        {
                            subscription.items.map((item, index) =>
                                <tr key={index}>
                                    <td width="40%">
                                        <Text>{t(`subscriptions.name.${item.name}`)}</Text>
                                    </td>
                                    <td width="30%">
                                        <Text>{dinero(item.price).toFormat()}</Text>
                                    </td>
                                    <td width="30%">
                                        { item.recurring &&
                                            <Badge color={'green'} variant={'filled'}>{t('subscriptions.recurring')}</Badge>
                                        }

                                        {
                                            !item.recurring &&
                                            <Badge variant={'filled'}>{t('subscriptions.oneTimePayment')}</Badge>
                                        }
                                    </td>
                                </tr>
                            )
                        }
                    </>
                )}
                </tbody>
            </Table>
        </Section>
    )
}