import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Index from "./pages";
import Initial from "./pages/initial";
import {loadStripe} from "@stripe/stripe-js";
// import i18n (needs to be bundled ;))
import "./lang/i18n";
import Page404 from "./pages/404";

export const stripePromise = function (country:string) {
    return loadStripe(
        process.env[`REACT_APP_${country.toUpperCase()}_STRIPE_PUBLIC_KEY`]
        ?? process.env["REACT_APP_STRIPE_PUBLIC_KEY"]
        ?? 'pk_test_51LKdzaFqHwWTr2khY0YoNQe56IuWMR95aZbnvMVImgHj0mb4nKI4iRQA06qehOXq9ZK9unjIGWIP1XeJ7gDi9nNU00xuLmSclS'
    );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
        <Routes>
            <Route path={'/:country'} element={<Initial/>}>
                <Route path={''} element={<Index/>}/>
            </Route>

            <Route path={'*'} element={<Page404/>}></Route>
        </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
