import Layout from "../components/Layout/Layout";
import Subscriptions from "../components/Subscription/Subscriptions";
import Invoices from "../components/Invoices/Invoices";
import FillCardData from "../components/SetupIntent/FillCardData";
import BasicDataLoader from "../components/BasicDataLoader";


export default function Index() {
    return (
        <>
            <BasicDataLoader>
                <Layout>
                    <Subscriptions/>
                    <FillCardData/>
                    <Invoices/>
                </Layout>
            </BasicDataLoader>
        </>
    )
}