import React from "react";
import {Grid, Image, Text} from "@mantine/core";
import {useLayoutStyles} from "./Layout.styles";
import Logo from "./Logo";
import {useCountryStore} from "../../store/CountryStore";
import {useUserStore} from "../../store/UserStore";
import {useTranslation} from "react-i18next";

interface Props {
    children?: React.ReactNode;
}

export default function Layout(props: Props) {
    const {t} = useTranslation();

    const {classes} = useLayoutStyles();
    const {country} = useCountryStore();
    const {client} = useUserStore();

    return (
        <div className={classes.container}>
            <div className={classes.leftBar}>
                <div>
                    <img src={country.logo} className={classes.logo}></img>
                </div>

                <div className={classes.information}>
                    <Text className={classes.label}>{t('sidebar.company')}</Text>
                    <Text className={classes.informationText}>{client.name}</Text>
                    <Text className={classes.label}>{t('sidebar.taxId')}</Text>
                    <Text className={classes.informationText}>{client.vatNumber}</Text>

                    {
                        client.bankAccount &&
                        <>
                            <Text className={classes.label}>{t('sidebar.accountNumber')}</Text>
                            <Text className={classes.informationText}>BANK PEKAO SA</Text>
                            <Text className={classes.informationText}>{client.bankAccount}</Text>
                            <Text className={classes.informationText}>SWIFT/BIC: PKOPPLPW</Text>
                        </>
                    }

                    {
                        client.supervisor &&
                        <>
                            <Text className={classes.label}>{t('sidebar.supervisor')}</Text>
                            <Text className={classes.informationText}>{client.supervisor.name.name} {client.supervisor.name.surname}</Text>
                            <Text className={classes.informationText}>{client.supervisor.email}</Text>
                            <Text className={classes.informationText}>{client.supervisor.phone}</Text>
                            <Text className={classes.informationText}>ul. Wirażowa 124 A, 02-145 Warszawa</Text>
                        </>
                    }
                </div>
            </div>
            <div className={classes.rightBar}>
                {props.children}
            </div>
        </div>
    )
}