import {createStyles} from "@mantine/core";

export const useLayoutStyles = createStyles((theme) => ({
    container: {
        height: '100%',
        display: 'flex',

        [`@media (max-width: ${theme.breakpoints.md}px)`]: {
            flexDirection: 'column'
        },
    },
    leftBar: {
        backgroundColor: '#d53f3f',
        width: '576px',
        padding: '64px 64px 40px 64px',

        [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
            flex: '0 0 40%'
        },

        [`@media (max-width: ${theme.breakpoints.md}px)`]: {
            width: '100%',
            padding: '40px 20px 40px 20px',
            flex: 0
        },
    },
    rightBar: {
        padding: '64px 80px 100px',
        paddingTop: '164px',
        flex: 1,

        [`@media (max-width: ${theme.breakpoints.md}px)`]: {
            width: '100%',
            padding: '40px 20px 40px 20px',
            flex: 0
        },
    },
    logo: {
        height: '60px'
    },

    information: {
        marginTop: '55px',
        color: '#fff'
    },

    informationText: {
        fontSize: '20px',
        color: '#fff',
        fontWeight: 700,

        [`@media (max-width: ${theme.breakpoints.md}px)`]: {
            fontSize: '16px'
        },
    },

    label: {
        fontSize: '14px',
        fontWeight: 500,
        marginTop: '2rem',
        color: '#fff',
    }
}))