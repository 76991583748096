import ApiMoney from "../Common/ApiMoney";

export interface Subscription {
    id: string;
    status: SubscriptionStatus,
    items: SubscriptionItem[]
}

export const SubscriptionStatus = {
    ACTIVE: "active",
    NO_PAYMENT_METHOD: "no_payment_method",
    STARTING: "starting",
    UNPAID: "unpaid",
};

export interface SubscriptionItem {
    name: string;
    price: ApiMoney;
    recurring: boolean;
}

type SubscriptionStatus = typeof SubscriptionStatus[keyof typeof SubscriptionStatus];

