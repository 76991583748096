import {Subscription, SubscriptionStatus} from "./Subscription";
import {useUserStore} from "../../store/UserStore";
import {useHttp} from "../../lib/http";
import {Collection} from "../Common/Collection";
import SetupIntent from "./SetupIntent";

export const useSubscriptionsApi = () => {
    const token = useUserStore().token;
    const http = useHttp();

    const loadSubscriptions = async (): Promise<Collection<Subscription>> => {
        return http.get<Collection<Subscription>>(`/purchaser/api/client/${token}/subscriptions`).then((r) => r.data);

        // return {
        //     items: [
        //         {
        //             status: SubscriptionStatus.NO_PAYMENT_METHOD,
        //             id: '123',
        //             items: [
        //                 {
        //                     name: 'gold_profile_contract',
        //                     price: {
        //                         cents: 50,
        //                         currency: 'EUR'
        //                     },
        //                     recurring: true
        //                 },
        //                 {
        //                     name: 'gold_profile_activation',
        //                     price: {
        //                         cents: 50,
        //                         currency: 'EUR'
        //                     },
        //                     recurring: false
        //                 },
        //             ]
        //         }
        //     ]
        // }
    }

    const loadSetupIntent = async (subscriptionId: string) => {
        return http.post<SetupIntent>(`/purchaser/api/client/${token}/subscription/${subscriptionId}/stripe/setup-payment`).then((r) => r.data);
    }
    return {
        loadSubscriptions,
        loadSetupIntent
    }
}
